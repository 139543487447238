.PageNotFound {
  padding: 20px;
  min-height: 80vh;
  display: flex;
  flex-flow: nowrap column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  gap: 1rem;
  &-logo {
    max-width: 10em;
    height: auto;
  }
  &-title {
    font-weight: normal;
    width: 90vw;
    max-width: 15em;
  }
}